import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from './content/Hero';
import Meta from '../shared/Meta';
import Triple from './content/Triple';
import LogoList from './content/LogoList';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './content/Triple.module.postcss';

class Catering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    return (
      <div>
        <Meta
          title={`Vendors - ${cityConfig.site_title}`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Vendors",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <Hero photo="/static/images/placeholder/rezz.jpg">
          <h1>Join Our Community</h1>
          <h3 className="u-mb2">Grow Your Business. Expand Your Reach</h3>
          <Link
            to="/vendor/register"
            className="Button"
          >
            Sign Up Now
          </Link>
        </Hero>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>How it works</h4>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about1.jpeg"
                title="Join Our Network"
                text={`Create a free profile and experience the benefits of ${cityConfig.site_title}'s networking efforts with over 2 million annual site users and more than 15k social media followers.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about2.jpg"
                title="Prime Vending Locations"
                text={`Book a spot with hassle-free permitting at one of over 30 locations in the ${cityConfig.city_name} area, ranging from office parks and breweries, to our large scale pods at Westlake & Occidental Park, Downtown Bellevue and Starbucks Center!`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about3.jpg"
                title="Keep In Touch"
                text={`Seek advice, get recommendations and swap spots with your fellow food ${cityConfig.vehicle_type.toLowerCase()}ers through our mailing list network of over 250 food ${cityConfig.vehicle_type.toLowerCase()} members in the surrounding ${cityConfig.city_name} area.`}
              />
            </div>
          </div>
        </section>

        <section className="NewSection">
          <LogoList />
        </section>

        <Hero photo="/static/images/placeholder/truck-wedding.jpg">
          <h2>Book More Catered Events</h2>
          <h3>Gain access to private catering and large events to maximize your schedule.</h3>
        </Hero>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Increase your food {cityConfig.vehicle_type.toLowerCase()} sales!</h3>
            <p>Feature your business on {cityConfig.site_title} and reach new customers!</p>
            <a
              className="Button"
              href="/vendor/register"
            >
              Register Now
            </a>
          </div>
        </section>

      </div>
    );
  }
}

Catering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Catering);
